@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
:root {
  --dark-green: #27464b;
  --black: #2b2b2b;
  --white: #fff;
  --theme-color: #323484;
  --light-green: #d0efe9;
  --secondary-color: #9496ff;
}
body , span , th , td{
    font-family: 'Montserrat', sans-serif !important;
}
.m-0 {
  margin: 0 !important;
}
.a_tag {
  color: var(--primary-color) !important;
}
.p-0 {
  padding: 0 !important;
}
.p-20 {
  padding: 20px !important;
}
.p-30 {
  padding: 30px !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.pr-30 {
  padding-right: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}
.py-20 {
  padding: 20px 0 !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mx-10 {
  margin: 0 10px !important;
}
.mx-20 {
  margin: 0 20px !important;
}
.my-10 {
  margin: 10px 0 !important;
}
.my-20 {
  margin: 20px 0 !important;
}
.my-40 {
  margin: 40px 0 !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
    margin-top: 30px !important;
  }
.mt-10 {
  margin-top: 10px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.fz-10 {
  font-size: 10px !important;
}
.fz-30 {
  font-size: 30px !important;
}
.fz-13 {
  font-size: 13px !important;
}
.fz-14 {
  font-size: 14px !important;
}
.fz-16 {
  font-size: 16px !important;
}
.fz-18 {
  font-size: 18px !important;
}
.fz-20 {
  font-size: 20px !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.semibold {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.w100 {
  width: 100% !important;
}
.w_auto {
  width: auto !important;
}
.flex-center{
    display: flex !important;
    align-items: center !important;
}
.login_container {
  background: #d2d0dd;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 100px 0;
  min-height: 78vh;
  justify-content: center;
}
.loginImg {
  object-fit: contain;
  max-width: 300px;
}
.login_container .ryt_content {
  background-color: #f3f4f8;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}
.login_container .left_content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 14px;
}
.login_container .left_content .company-name {
  margin-bottom: auto;
  color: var(--theme-color) !important;
}
.login_container .login-user-type {
    font-weight: 700;
    margin: 0 0 20px;
    font-size: 28px;
    /* color: var(--default-green); */
    color: var(--black);
}
.login_container .content_section {
    margin-bottom: auto;
    padding: 0 80px;
}
.textfield{
    border-radius: 6px !important;
    height: 40px;
    background-color: #f3f4f8ad;
    margin: 0 !important;
}
.textfield fieldset{
    border: 0;
}
.textfield input{
    font-size: 14px;
    padding: 8.5px 14px;
}
:-webkit-autofill{
    background-color: transparent !important;
    
}
.themeBtn{
    box-shadow: var(--theme-color) !important;
    background-color: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
    color: #fff !important;
    padding: 6px 20px !important ;
    border-radius: 6px !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    letter-spacing: 1px !important;
    text-transform: capitalize !important;
    position: relative !important;
    overflow: hidden !important;
    transition: all 0.6s ease !important;
    z-index: 1;
}
.themeBtn::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    background: #fff;
    z-index: -1;
    /* width: 110%; */
    width: 310px;
    height: 0%;
    transform: translate(-50%, -50%) rotate(45deg);
    /* opacity: 0.9; */
    transition: all 0.6s ease;

}
.themeBtn:hover::before{
    /* height: 380%; */
    height: 310px;
    transition: all 0.6s ease;
}
.themeBtn:hover{
    color: var(--theme-color) !important;
    border: 1px solid var(--theme-color) !important;
    box-shadow: 0 0.125rem 2rem rgba(36, 105, 92, 0.1), 0 0.0625rem 1rem rgba(36, 105, 92, 0.1);
    padding: 5px 19px !important;

}
.label{
    color: #5A647B !important;
    font-weight: 600 !important;
    margin-bottom: 10px !important;
    font-size: 13px !important;
}
.text-primary{
    color: var(--theme-color) !important;
}
.mainnew1 > div{
    min-height: 78vh;
}
.sidebar_container > .MuiPaper-root{
    padding: 20px;
}
.sidebar_container .sidebar_in{
    background-color: var(--theme-color);
    border-radius: 10px !important;
    height: 100%;
}
.sidebar_container .sidebar_in h5{
    font-weight: 600;
    color: var(--white);
    font-size: 30px;
    padding-right: 38px;
}
.sidebar_container .pro_img {
    width: 80px;
    height: 80px;
    margin: auto;
    background-color: var(--secondary-color);
    padding: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sidebar_container .sidebar_prof{
    margin: 40px 0;
    text-align: center;
}
.sidebar_prof .name {
    color: var(--white);
    font-size: 18px;
    margin: 15px 5px;
    font-weight: 600;
}
.sidebar_container .MuiListItemButton-root{
    color: var(--white) !important;
    font-weight: 700;
}
.sidebarClose.sidebar_container .pro_img {
    width: 36px;
    height: 36px;
    padding: 2px;
}
.sidebarClose.sidebar_container .MuiDrawer-paper{
    width: calc(64px + 40px) !important;
}
.sidebarClose .sidebar_prof .name{
    display: none;
}
.sidebar_container svg{
    color: white;
    font-size: 18px;
}
.content-wrapper{
    padding: 0 20px 0 320px;
    min-height: 99vh;
    background-color: #F2F2FF;
}
.sidebarClose 
.content-wrapper{
    padding: 0 20px 0 120px;
}
.customCard{
    border-radius: 2px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    background-color: white;
}
.MuiTableRow-head{
    background: #F4F4FB;
}
.MuiTableRow-head th{
    color: #5A647B;
    font-weight: 600;
    padding: 8px 16px;
}
.customCard .MuiPaper-root{
    box-shadow: none;
    border-bottom: 0;
}
.page_main .content-wrapper{
    padding-top: 50px;
    padding-bottom: 50px;
}
.input{
    outline: 0;
    width: 215px;
    height: 36px;
    padding: 7px 12px;
    padding-right: 36px;
    font-size: 13px;
    line-height: 1.5384616;
    color: #333333;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-right: 10px;
}
.select{
    outline: 0;
    width: 215px;
    height: 36px;
    padding: 7px 12px;
    padding-right: 36px;
    font-size: 13px;
    line-height: 1.5384616;
    color: #333333;
    background-color: #fff;
    border-radius: 3px;
    margin-right: 10px;
}
.select fieldset{
    border-color: rgba(0, 0, 0, 0.1);
}
.select #demo-simple-select{
    padding: 0;
    font-size: 13px;
    color: #333333;
}


.themeBtn18{
  box-shadow: var(--theme-color) !important;
  background-color: var(--theme-color) !important;
  border-color: var(--theme-color) !important;
  color: #fff !important;
  padding: 6px 20px !important ;
  border-radius: 6px !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  letter-spacing: 1px !important;
  text-transform: capitalize !important;
  position: relative !important;
  overflow: hidden !important;
  transition: all 0.6s ease !important;
  z-index: 1;
}
.themeBtn18::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  background: #fff;
  z-index: -1;
  /* width: 110%; */
  width: 310px;
  height: 0%;
  transform: translate(-50%, -50%) rotate(45deg);
  /* opacity: 0.9; */
  transition: all 0.6s ease;

}

.colorchangedrawer{
  background-color: #42A5F5!important;
}
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.pagination-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-page {
  margin: 0 10px;
  font-size: 16px;
  font-weight: bold;
}

.themeBtnblue{
  box-shadow: '#053aeb' !important;
  background-color: '#053aeb' !important;
  border-color: '#053aeb' !important;
  color: black !important;

  font-size: 13px !important;
  font-weight: 700 !important;
  outline: 1px solid #053aeb !important;
}