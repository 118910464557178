@media (max-width:992px){
    .login_container .content_section{
        padding: 0 20px;
    }
}
@media (max-width:768px){
    .mainnew1 > div{
        flex-direction: column-reverse;
      
        justify-content: flex-end;
    }
    .login_container .ryt_content{
        border-top-left-radius: 12px;
        border-bottom-right-radius: 0;
        padding: 50px 0;
    }
    .mainnew1 .MuiGrid-item{
        max-width: 100%;
        flex-basis: 100%;
    }
    .login_container .content_section {
        padding: 50px 20px;
    }
    .login_container{
        padding: 30px 0;
    }
}

