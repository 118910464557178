.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* login css */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.login-form h2 {
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #f3f6f9 inset !important;
}

.buttonlogin {
  width: 100%;
  padding: 10px;
  background-color: #7e0ff2;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #7e0ff2;
}

.profile-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.profile-header {
  text-align: center;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.profile-details,
.profile-extra {
  margin-top: 20px;
}

.profile-details h2,
.profile-extra h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.profile-details p,
.profile-extra p {
  margin-bottom: 8px;
}

.profile-details strong,
.profile-extra strong {
  font-weight: bold;
}

/* Location */
.leaflet-container {
  width: 90vw;
  height: 90vh;
  margin-left: 70px;
}

/* Login start */
.loginForm {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #f2f6ff;
}

.loginForm form {
  width: 100%;
  max-width: 500px;
  background: #fff;
  padding: 60px;
  border-radius: 3px;
  text-align: center;
}
.registerForm form {
  max-width: 550px;
}
.loginForm form h3 {
  color: #31507d;
  font-size: 26px;
  font-weight: var(--fontBold);
}
.loginForm form h5 {
  font-size: 16px;
  font-weight: 400;
  color: #9ba9c0;
  margin: 5px 0 15px 0;
}
.loginForm form a {
  text-decoration: none;
}
.loginForm form a.signinBtn {
  padding: 12px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 10px;
  display: block;
  border-radius: 3px;
  box-shadow: none;
}
.loginForm .loginOptionText {
  margin: 20px 0;
  position: relative;
}
.loginForm .loginOptionText span {
  position: relative;
  z-index: 11;
  padding: 0 10px;
  background: #fff;
  color: #999;
}
.loginForm .loginOptionText:before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: #ddd;
  top: 0;
  margin: auto;
}

@media (max-width: 600px) {
  .loginForm {
    height: auto;
  }

  .loginForm form {
    padding: 20px 30px 40px 30px;
  }

  .filterHeading {
    margin-top: 40px !important;
  }
}

.heading {
  font-size: 30px !important;
  margin-bottom: 30px !important;
}

.main {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-width: 900px;
  margin: 50px auto;
  padding: 50px;
  text-align: center;
}
.mainnew {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-width: 600px;
  margin: 0px auto;
  padding: 50px;
  text-align: center;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.mainnew1 {
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  background: #fff;
  border-radius: 12px;
  width: 1200px;
  margin: 0 20px;
}
.mainnew > div {
  width: 48%;
}
.semibold {
  font-weight: 600 !important;
}
.fz-18 {
  font-size: 18px;
}

.MuiFormLabel-root {
  line-height: 15px !important;
}

.theme-btn {
  background-color: white !important;
  color: red !important;
  border-left: 1px solid var(--border-color);
  border-radius: 0;
  text-align: right !important;
}
.headingBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
  align-items: center;
  flex-wrap: wrap;
}
.theme-btn {
  background-color: #1976d2 !important;
  color: white !important;
  max-height: 34px;
  text-transform: capitalize !important;
  box-shadow: none !important;
}
.form {
  border-radius: 4px;
  border: 1px solid #ddd;
  max-width: 300px;
  margin: 20px auto;
  padding: 20px 0;
}
.btn_grp {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px 0;
  text-align: center;
}
.mr-10 {
  margin-right: 10px !important;
}

.mr-8 {
  margin-right: 8px !important;
}
.start_icon {
  width: 20px !important;
  height: 20px !important;
  color: #aaa;
  margin-right: 5px;
}
.star {
  color: red;
  font-size: 14px !important;
  line-height: 1 !important;
}
.drawer .logoDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6px 0;
  width: 100%;
  padding-left: 40px;
}

.drawer .css-2t95ll {
  min-height: 64px;
}

.loginContainer {
  min-height: 100vh;
}

.side-img {
  display: flex;
  align-items: center;
  background-color: #091865;
  flex-direction: column !important;
  justify-content: space-between;
  overflow: hidden;
}

.side-img .side-pic {
  max-width: 700px;
}

.logo {
  max-width: 100px;
  /* text-align: center; */
}

/* //new */

.redirect {
  font-size: 15px;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 6px;
}
.mb-0 {
  margin-bottom: 0;
}
.text-center {
  text-align: center !important;
}

.btn-17,
.btn-17 *,
.btn-17 :after,
.btn-17 :before,
.btn-17:after,
.btn-17:before {
  border: 0 solid;
  box-sizing: border-box;
}

.btn-17 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #000;
  background-image: none;
  color: #fff;
  cursor: pointer;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 100%;
  font-weight: 900;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
  text-transform: uppercase;
}

.btn-17:disabled {
  cursor: default;
}

.btn-17:-moz-focusring {
  outline: auto;
}

.btn-17 svg {
  display: block;
  vertical-align: middle;
}

.btn-17 [hidden] {
  display: none;
}

.btn-17 {
  border-radius: 99rem;
  border-width: 2px;
  /* padding: 1.1rem 3.5rem; */
  padding: 0.6rem 1.4rem;
  z-index: 0;
  border-color: #000;
  letter-spacing: 1.1px;
}

.btn-17,
.btn-17 .text-container {
  overflow: hidden;
  position: relative;
}

.btn-17 .text-container {
  display: block;
  mix-blend-mode: difference;
}

.btn-17 .text {
  display: block;
  position: relative;
}

.btn-17:hover .text {
  -webkit-animation: move-up-alternate 0.3s forwards;
  animation: move-up-alternate 0.3s forwards;
}

@-webkit-keyframes move-up-alternate {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(80%);
  }

  51% {
    transform: translateY(-80%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes move-up-alternate {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(80%);
  }

  51% {
    transform: translateY(-80%);
  }

  to {
    transform: translateY(0);
  }
}

.btn-17:after,
.btn-17:before {
  --skew: 0.2;
  background: #fff;
  content: "";
  display: block;
  height: 102%;
  left: calc(-50% - 50% * var(--skew));
  pointer-events: none;
  position: absolute;
  top: -102%;
  transform: skew(calc(150deg * var(--skew))) translateY(var(--progress, 0));
  transition: transform 0.2s ease;
  width: 100%;
}

.btn-17:after {
  --progress: 0%;
  left: calc(50% + 50% * var(--skew));
  top: 102%;
  z-index: -1;
}

.btn-17:hover:before {
  --progress: 100%;
}

.btn-17:hover:after {
  --progress: -99%;
}

span#ctl00_ContentPlaceHolder1_lbl_centerPaneltext {
  display: none;
}
.redirect {
  text-decoration: none !important;
  color: #f6f6f6 !important;
}

.customTableCell {
  margin-left: 200px !important;
}

.tableviewnew {
  margin-top: 10 !important;
  width: 40% !important;
  outline: 1px solid rgb(39, 39, 39) !important;
}
.tableviewnew1 {
  margin-top: 10 !important;
  width: 20% !important;
  outline: 1px solid rgb(39, 39, 39) !important;
}
.tableviewnew2 {
  margin-top: 10 !important;
  width: 40% !important;
  outline: 1px solid rgb(39, 39, 39) !important;
  overflow: scroll!important;
  height: 880px;
}
.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.col {
  flex: 1;
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  .col {
      flex: 100%;
      margin-right: 0;
  }
}